import posthog from 'posthog-js'; // new
import { useEffect } from "react";

export default function PosthogCapture() {
    useEffect(() => {
        if (!window.location.hostname.includes("viewer.augmia.io")) {
            posthog.opt_out_capturing()
        }
    }, [])
    return <></>
}