import { Box, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function Logo() {
    return <Box maxHeight='200px' display='flex' position='absolute' top='15px' justifyContent='center' left='50%' transform='translate(-50%, 0%)'>
        <Link to={'/'}>
            <Image width='118px' height='46px' src='/augmia/augmia-logo.svg' alt='Logo' />
        </Link>
    </Box>
}
