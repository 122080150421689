import { Avatar, Heading, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import AppLayout from '../../../../components/AppLayout';
import PrimaryButton from '../../../../components/PrimaryButton';

export default function StartPage({ arApps, onStart }) {
    const [query] = useSearchParams();
    const { t } = useTranslation('app');
    const textSource = query.get('isCollection') ? arApps[0].collection : arApps[0];
    const { author = {} } = textSource;

    const requestWebcamPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            stream.getTracks().forEach(track => track.stop());
        } catch (err) {
            console.error('Error requesting webcam access: ', err);
            alert('Webcam access denied or not available.');
        }
    };

    const handleStartButtonClick = async () => {
        await requestWebcamPermission();

        // Play and pause videos to unlock autoplay
        const videos = document.querySelectorAll('video');
        const playPromises = [];
        videos.forEach(video => {
            if (video.id !== "video") {
                video.muted = true;
                const playPromise = video.play();
                if (playPromise !== undefined) {
                    playPromises.push(
                        playPromise
                            .then(() => {
                                // Add a slight delay before pausing
                                setTimeout(() => {
                                    video.pause();
                                    video.currentTime = 0;
                                    video.muted = false;
                                }, 100); // 100 milliseconds delay
                            })
                            .catch(error => {
                                console.error('Error playing video:', error);
                            })
                    );
                }
            }
        });

        await Promise.all(playPromises);

        // Start the AR session
        if (onStart) {
            onStart();
        }
    };

    return (
        <AppLayout gridTemplateRows="50px 2fr 100px">
            <VStack gap="2.5rem">
                <Heading as="h2" color="#FFF8ED" fontWeight="600" marginBottom="10px">
                    {textSource.title}
                </Heading>
                <Text color="white">{textSource.description}</Text>
                <VStack spacing={1} align="center">
                    <Text color="white">Made with ❤️ by</Text>
                    <HStack spacing={3} align="center">
                        <Avatar name={author.name} src={``} size="sm" />
                        <Link href="/detail-page" color="white">
                            {author.name}
                        </Link>
                    </HStack>
                </VStack>
                <VStack>
                    <PrimaryButton buttonHandler={handleStartButtonClick}>
                        {t('startButton.text')}
                    </PrimaryButton>
                    <Text color="white">You will need to allow camera access.</Text>
                </VStack>
            </VStack>
        </AppLayout>
    );
}
