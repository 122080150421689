export enum Languages {
    EN = 'en',
    CS = 'cs'
}

export const fallbackLng: Languages = Languages.EN;
export const languages: Languages[] = [fallbackLng, Languages.CS];
export const languagesHuman = {
    [Languages.EN]: 'English',
    [Languages.CS]: 'Česky',
};

export const languagesOptions = [
    {
        key: Languages.EN,
        name: languagesHuman[Languages.EN],
        flag: '/flag-en.svg',
    },
    {
        key: Languages.CS,
        name: languagesHuman[Languages.CS],
        flag: '/flag-cz.svg',
    },
];

export const defaultNS = 'common';
