
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorMode
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function LandscapeOrientationPopup() {
    const location = useLocation();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false)
    const { colorMode } = useColorMode()

    useEffect(() => {
        const checkOrientation = () => {
            const isLandscape = window.innerWidth > window.innerHeight
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
            setIsOpen(isLandscape && isMobile)
        }

        // Check orientation on mount and add event listener
        checkOrientation()
        window.addEventListener('resize', checkOrientation)

        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', checkOrientation)
    }, [])

    const bgColor = colorMode === "dark" ? "#1A202C" : "white"
    const textColor = colorMode === "dark" ? "white" : "#1A202C"

    return (
        <Modal isOpen={isOpen} onClose={() => {
            setIsOpen(false);
            // navigate(`${location.pathname}${location.search}`)
            window.location.reload();

        }}
            isCentered>
            <ModalOverlay />
            <ModalContent bg={bgColor} color={textColor}>
                <ModalHeader>
                    Orientation Notice
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>This application is designed for portrait mode only. Please rotate your device to continue.</Text>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme="purple"
                        onClick={() => {
                            setIsOpen(false)
                            // navigate(`${location.pathname}${location.search}`)
                            window.location.reload();

                        }}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}