export const isAppInstalledOnAndroid = (): boolean => {
    // @ts-expect-error test
    const isInstalled = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

    if (isInstalled) {
        // The app is installed on Android
        console.log('App is installed on Android');
        return true;
    }
    // The app is not installed on Android
    console.log('App is not installed on Android');
    return false;
};

export const isAppInstalledOnIOS = (): boolean => {
    // @ts-expect-error test
    if (window.navigator.standalone) {
        // The app is installed on iOS
        console.log('App is installed on iOS');
        return true;
    }
    // The app is not installed on iOS
    console.log('App is not installed on iOS');
    return false;
};

export const isAppInstalled = (): boolean => {
    // Detect Android browser
    const isAndroid = /android/i.test(navigator.userAgent);

    // Detect iOS browser
    // @ts-expect-error test
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (isAndroid) {
        return isAppInstalledOnAndroid();
    }

    if (isIOS) {
        // Code to run when the browser is iOS
        return isAppInstalledOnIOS();
    }

    return false;
};
