import React from 'react';
import { HStack, MenuItemOption, Image, Text, MenuOptionGroup, Menu } from '@chakra-ui/react';
import { Languages, languagesOptions } from '../i18n/locales/settings';
import { useTranslation } from 'react-i18next';

export default function LanguageSelector() {
    const { t, i18n } = useTranslation('topBanner');
    const languageItems = languagesOptions.map(({ key, name, flag }) => {
        return (
            <MenuItemOption bg='#12111C' value={key} key={key} onClick={() => { i18n.changeLanguage(key); }}>
                <HStack>
                    <Image alt={`${key}-flag`} boxSize='14px' src={flag} />
                    <Text>{name}</Text>
                </HStack>
            </MenuItemOption >
        );
    });
    return (
        <Menu>
            <MenuOptionGroup defaultValue={i18n.language === Languages.EN ? Languages.EN : Languages.CS} title={t('language')} type='radio'>
                {languageItems}
            </MenuOptionGroup>
        </Menu>
    );
}
