import { Box, HStack, Image, Link } from '@chakra-ui/react';

export default function SocialIcons() {
    return (
        <>
            <Box>
                <HStack spacing='30px' justifyContent='center'>
                    <Link href='https://instagram.com/relive.ar' target='_blank'>
                        <Image src='/instagram.svg' />
                    </Link>
                    <Link href='https://facebook.com/Relive.AR' target='_blank'>
                        <Image src='/facebook.svg' />
                    </Link>
                </HStack>
            </Box>
        </>
    );
}
