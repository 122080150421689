import { Alert, AlertIcon, Button, CloseButton, HStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAppInstalledOnAndroid, isAppInstalledOnIOS } from '../libs/progresive-web-app';

// @TODO: For all platfroms show the bookmark option to create an illusion of app
export default function AddToHomeScreenButton() {
    const { t } = useTranslation('app', { keyPrefix: "home" })
    const [promptEvent, setPromptEvent] = useState<any>(null);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event: Event) => {
            event.preventDefault();
            console.log('GOT promt event');
            setPromptEvent(event);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const isIOS = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    const isAndroid = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /android/.test(userAgent);
    };

    const handleInstallClick = () => {
        console.log('Installing', promptEvent);
        if (isIOS() || isAndroid()) {
            setShowAlert(true);
        }
        if (promptEvent) {
            promptEvent.prompt();
            promptEvent.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('Website added to home screen!');
                }
            });
        }
    };

    const handleCloseButton = () => {
        setShowAlert(false);
    };

    const shouldShowOnIos = isIOS() && !isAppInstalledOnIOS();
    const shouldShowOnAndroid = isAndroid() && !isAppInstalledOnAndroid();
    const showInstallButton = promptEvent || shouldShowOnIos || shouldShowOnAndroid;

    return <>
        {showAlert && (
            <Alert status="info" fontSize='12px' lineHeight='22px' fontWeight='400' bg='#FFA3FB33' color='#FFA3FB' flexDirection='column'>
                <HStack justifyContent='space-between'>
                    <AlertIcon color='#FFA3FB' />
                    <CloseButton
                        alignSelf='flex-start'
                        onClick={handleCloseButton} />
                </HStack>
                {t('addToHomeText')}
            </Alert>
        )}
        {showInstallButton && !showAlert
            && <Button onClick={handleInstallClick}
                color='#FFA3FB' bg='#FFA3FB33' borderRadius='10px' padding='10px 28px 10px 28px' fontSize='16px' fontWeight='600' lineHeight='22px'>
                {t('addToHomeButton')}
            </Button>
        }
    </>;
};
