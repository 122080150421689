import { Box, VStack, chakra } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DotLoader } from 'react-spinners';
import { funnyLoadingLabels as funnyLoadingLabelsCS } from '../data/funnyLoadingTextsCS';
import { funnyLoadingLabels as funnyLoadingLabelsEN } from '../data/funnyLoadingTextsEN';
import { Languages } from '../i18n/locales/settings';


function getRandomIndex(funnyLoadingLabels: any) {
    return Math.floor(Math.random() * funnyLoadingLabels.length);
}

export default function LoadingComponent() {
    const { i18n } = useTranslation();
    const funnyLoadingLabels = i18n.language === Languages.EN ? funnyLoadingLabelsEN : funnyLoadingLabelsCS;
    const [progress, setProgress] = useState(0);
    const [loadingText, setLoadingText] = useState<string>(funnyLoadingLabels[getRandomIndex(funnyLoadingLabels)]);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingText(funnyLoadingLabels[getRandomIndex(funnyLoadingLabels)]);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let refreshRate = 80;
        const interval = setInterval(() => {
            if (progress > 40) {
                refreshRate = 300;
            }
            if (progress > 80) {
                refreshRate = 400;
            }
            if (progress < 95) {
                setProgress(progress + 1);
            } else {
                clearInterval(interval);
            }
        }, refreshRate);

        return () => clearInterval(interval);
    }, [progress]);

    return (
        <Box height='100%' justifyContent='space-between' display='flex' flexDirection='column'>
            <DotLoader cssOverride={{ margin: '50px auto' }} color="#9E8CF2" size={120} />
            <VStack>
                <chakra.label fontSize='28' fontWeight='600' color='#FFF'>{progress}%</chakra.label>
                <chakra.label color='#FFF'>{loadingText}</chakra.label>
            </VStack>
        </Box>
    );
};
