import { create } from 'zustand';
import { ChatwootSlice, createChatwootSlice } from './chatwoot';
import { selectedEffectEnum } from '../model-types/AppModels';

type State = {
    selectedEffect: selectedEffectEnum;
    isPrefetchingAssets: boolean;
    isLoading: boolean;
    isMobile: boolean;
    isVideoLoaded: boolean;
    isUnmouting: boolean;
    isVideoMuted: boolean;
    videoRef?: any;
    showArViewer: boolean;
};

type Action = {
    updateSelectedEffect: (selectedEffect: State['selectedEffect']) => void;
    updateIsLoading: (loading: boolean) => void;
    updateIsPrefetchingAssets: (isPrefetchingAssets: boolean) => void;
    updateIsVideoLoaded: (isLoaded: boolean) => void;
    setEffectAndLoad: (selectedEffect: State['selectedEffect']) => void;
    setIsUnmouting: (isUnmouting: boolean) => void;
    setShowArViewer: (showArViewer: boolean) => void;
    toggleMute: () => void;
    resetArState: () => void;
    reset: () => void;
    resetArScreen: () => void;
    setVideoRef: (videoRef: any) => void;
};

const initialState: State = {
    isVideoMuted: false,
    selectedEffect: selectedEffectEnum.HEARTS_EFFECT,
    isPrefetchingAssets: false,
    isLoading: true,
    isMobile: true,
    isVideoLoaded: false,
    isUnmouting: false,
    showArViewer: false,

};
export const useAppStore = create<State & Action & ChatwootSlice>((...args) => {
    const [set, get] = args;
    return {
        ...createChatwootSlice(...args),
        ...initialState,
        updateSelectedEffect: (selectedEffect) => set(() => ({ selectedEffect })),
        updateIsLoading: (isLoading) => set(() => ({ isLoading })),
        updateIsPrefetchingAssets: (isPrefetchingAssets) => set(() => ({ isPrefetchingAssets })),
        updateIsVideoLoaded: (isVideoLoaded) => set(() => ({ isVideoLoaded })),
        setEffectAndLoad: (selectedEffect) => {
            set(() => ({ selectedEffect, isPrefetchingAssets: true, isLoading: true, isUnmouting: true }));
        },
        setIsUnmouting: (isUnmouting) => set({ isUnmouting }),
        toggleMute: () => {
            const { isVideoMuted } = get();

            set({
                isVideoMuted: !isVideoMuted,
            });
        },
        resetArState: () => set({
            isVideoMuted: false,
            selectedEffect: selectedEffectEnum.HEARTS_EFFECT,
            isPrefetchingAssets: false,
            isLoading: false,
            isMobile: true,
            isVideoLoaded: false,
            isUnmouting: false,
        }),
        resetArScreen: () => set({
            isLoading: true,
            isUnmouting: true,
            isPrefetchingAssets: true,
        }),
        reset: () => set({ ...initialState }),
        setVideoRef: (videoRef) => set({ videoRef }),
        setShowArViewer: (showArViewer) => set({ showArViewer }),
    };
},
);
