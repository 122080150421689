import { Box, Button } from '@chakra-ui/react';
import { MouseEventHandler, ReactNode } from 'react';

interface PrimaryButtonProps {
    buttonHandler: MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
    boxProps?: any;
    buttonProps?: any;

}
export default function PrimaryButton(props: PrimaryButtonProps) {
    const { buttonHandler, children, boxProps = {}, buttonProps = {} } = props;
    // emtpy commit
    return (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            width='244px'
            height='72px'
            borderRadius='12px'
            border='1px solid rgba(158, 140, 242, 0.20)'
            margin='0 auto'
            {...boxProps}
        >
            <Button
                display='flex'
                justifyContent='center'
                alignItems='center'
                width='220px'
                height='54px'
                padding='14px 0px 14px 0px'
                fontSize='22px'
                fontWeight='700'
                lineHeight='22px'
                margin='12px'
                color='#000'
                background='#9E8CF2 20%'
                _hover={{ background: '#9E8CF2 20%', color: '#000' }}
                onClick={buttonHandler}
                {...buttonProps}
            >
                {children}
            </Button>
        </Box>
    );
}
