import { createTRPCReact } from "@trpc/react-query";
import type { ApiRouter } from "api/trpc/router";


export const apiClient = createTRPCReact<ApiRouter>({});


export const getSubdomain = () => {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    // Adjust the logic if you have different levels of subdomains
    if (parts.length > 2) {
        return parts[0];
    }
    return null;
};