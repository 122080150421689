import { useEffect } from "react";
import LoadingWithStore from "../../../components/LoadingWithStore";
import { useAppStore } from "../../../stores/AppStore";
import ArifyPage from "../arify/page";

export default function DCGCardPage() {
    const updateIsLoading = useAppStore(state => state.updateIsLoading)
    // const { updateTheme } = useThemeUpdater();

    useEffect(() => {
        setTimeout(() => { updateIsLoading(false) }, 2000)
    }, []);
    const generateArApps = (mindUrls) => {
        // Base template for the arApp
        const baseArApp = {
            title: "Business Card",
            description: "AR promo video on a business card",
            type: "IMAGE_TRACKING",
            author: {
                name: "DCG",
            },
            trackingConfig: {
                dCutOff: 0.007,
                mindUrl: "", // This will be replaced
                "filterBeta": 0.001,
                "missTolerance": 30,
                "cutOfFrequency": 0.001,
                "targetImageUrl": "https://l1qlfhcftfsxiiqs.public.blob.vercel-storage.com/cm2dcuuae0005idd1xepr5wwe/cm2ddabmo000didd1dxcr9qge/DCG%20Business%20Cards%209x5%20cm-1-7LXxdQPJgdIuWkK4RK122a4bFvzqno.png",
                "warmUpTolerance": 30,
                "showPerformanceMetrics": false,
                "useLinearInterpolation": false,
                "linearInterpolationFactor": 0
            },
            "items": {
                "GpjWfz": {
                    "id": "GpjWfz",
                    "src": "/custom-ar/dcg/dcg-card-smaller.mp4",
                    "type": "videoPlane",
                    "scale": {
                        "x": 1,
                        "y": 0.5625,
                        // "z": 1
                    },
                    "position": {
                        "x": 0,
                        "y": 0,
                        "z": 0.00001
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    }
                }
            },
        };

        // Generate arApps by mapping over the mindUrls
        return mindUrls.map((mindUrl) => {
            // Create a deep copy of the baseArApp
            const arApp = JSON.parse(JSON.stringify(baseArApp));
            // Set the mindUrl in the trackingConfig
            arApp.trackingConfig.mindUrl = mindUrl;
            return arApp;
        });
    };

    // Array of mindUrls
    const mindUrls = [
        // people
        "/custom-ar/dcg/data-michal.mind",
        "/custom-ar/dcg/data-enzo.mind",
        "/custom-ar/dcg/data-dominik.mind",
        "/custom-ar/dcg/data-kristyna.mind",
        // generics
        "/custom-ar/dcg/data-group.mind",
        "/custom-ar/dcg/data-back.mind",
        // Add more mindUrls as needed
    ];

    // Generate the arApps array
    const arApps = generateArApps(mindUrls);

    const dcgArApp2 = {
        title: "Business Card",
        description: "AR promo video on a business card",
        type: "IMAGE_TRACKING",
        author: {
            name: "DCG",
        },
        "trackingConfig": {
            "dCutOff": 0.007,
            "mindUrl": "/custom-ar/dcg/data-michal.mind",
            "filterBeta": 0.001,
            "missTolerance": 30,
            "cutOfFrequency": 0.001,
            "targetImageUrl": "https://l1qlfhcftfsxiiqs.public.blob.vercel-storage.com/cm2dcuuae0005idd1xepr5wwe/cm2ddabmo000didd1dxcr9qge/DCG%20Business%20Cards%209x5%20cm-1-7LXxdQPJgdIuWkK4RK122a4bFvzqno.png",
            "warmUpTolerance": 20,
            "showPerformanceMetrics": false,
            "useLinearInterpolation": false,
            "linearInterpolationFactor": 0
        },
        "items": {
            "GpjWfz": {
                "id": "GpjWfz",
                "src": "/custom-ar/dcg/dcg-card-smaller.mp4",
                "type": "videoPlane",
                "scale": {
                    "x": 1,
                    "y": 0.5625,
                    // "z": 1
                },
                "position": {
                    "x": 0,
                    "y": 0,
                    "z": 0.00001
                },
                "rotation": {
                    "x": 0,
                    "y": 0,
                    "z": 0
                }
            }
        },
    }



    return <>
        <LoadingWithStore />
        <ArifyPage arApps={arApps} />
    </>
}