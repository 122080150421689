"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import type { PropsWithChildren } from "react";
import { useState } from "react";
import superjson from "superjson";
import { apiClient } from "../libs/api-client";

export function ApiClientProvider({ children }: PropsWithChildren) {
  const baseUrl = "https://augmia.io";

  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    apiClient.createClient({
      links: [
        httpBatchLink({
          url: baseUrl + "/api",
          transformer: superjson,
        }),
      ],
    }),
  );
  return (
    <apiClient.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </apiClient.Provider>
  );
}
