 

import { extendTheme } from '@chakra-ui/react';
import { Global } from '@emotion/react';

const scope = ""
export const Fonts = () => (
    <Global styles={`
    @font-face {
        font-family: 'CabinetGrotesk-Variable';
        src: url('/assets/cabinet/CabinetGrotesk-Variable.woff2') format('woff2'),
             url('/assets/cabinet/CabinetGrotesk-Variable.woff') format('woff'),
             url('/assets/cabinet/CabinetGrotesk-Variable.ttf') format('truetype');
             font-weight: 100 900;
             font-display: swap;
             font-style: normal;
      }
      @font-face {
        font-family: 'CabinetGrotesk-Thin';
        src: url('/assets/cabinet/CabinetGrotesk-Thin.woff2') format('woff2'),
             url('/assets/cabinet/CabinetGrotesk-Thin.woff') format('woff'),
             url('/assets/cabinet/CabinetGrotesk-Thin.ttf') format('truetype');
             font-weight: 100;
             font-display: swap;
             font-style: normal;
      }
      @font-face {
        font-family: 'CabinetGrotesk-Extralight';
        src: url('/assets/cabinet/CabinetGrotesk-Extralight.woff2') format('woff2'),
             url('/assets/cabinet/CabinetGrotesk-Extralight.woff') format('woff'),
             url('/assets/cabinet/CabinetGrotesk-Extralight.ttf') format('truetype');
             font-weight: 200;
             font-display: swap;
             font-style: normal;
      }
      @font-face {
        font-family: 'CabinetGrotesk-Light';
        src: url('/assets/cabinet/CabinetGrotesk-Light.woff2') format('woff2'),
             url('/assets/cabinet/CabinetGrotesk-Light.woff') format('woff'),
             url('/assets/cabinet/CabinetGrotesk-Light.ttf') format('truetype');
             font-weight: 300;
             font-display: swap;
             font-style: normal;
      }
      @font-face {
        font-family: 'CabinetGrotesk-Regular';
        src: url('/assets/cabinet/CabinetGrotesk-Regular.woff2') format('woff2'),
             url('/assets/cabinet/CabinetGrotesk-Regular.woff') format('woff'),
             url('/assets/cabinet/CabinetGrotesk-Regular.ttf') format('truetype');
             font-weight: 400;
             font-display: swap;
             font-style: normal;
      }
      @font-face {
        font-family: 'CabinetGrotesk-Medium';
        src: url('/assets/cabinet/CabinetGrotesk-Medium.woff2') format('woff2'),
             url('/assets/cabinet/CabinetGrotesk-Medium.woff') format('woff'),
             url('/assets/cabinet/CabinetGrotesk-Medium.ttf') format('truetype');
             font-weight: 500;
             font-display: swap;
             font-style: normal;
      }
      @font-face {
        font-family: 'CabinetGrotesk-Bold';
        src: url('/assets/cabinet/CabinetGrotesk-Bold.woff2') format('woff2'),
             url('/assets/cabinet/CabinetGrotesk-Bold.woff') format('woff'),
             url('/assets/cabinet/CabinetGrotesk-Bold.ttf') format('truetype');
             font-weight: 700;
             font-display: swap;
             font-style: normal;
      }
      @font-face {
        font-family: 'CabinetGrotesk-Extrabold';
        src: url('/assets/cabinet/CabinetGrotesk-Extrabold.woff2') format('woff2'),
             url('/assets/cabinet/CabinetGrotesk-Extrabold.woff') format('woff'),
             url('/assets/cabinet/CabinetGrotesk-Extrabold.ttf') format('truetype');
             font-weight: 800;
             font-display: swap;
             font-style: normal;
      }
      @font-face {
        font-family: 'CabinetGrotesk-Black';
        src: url('/assets/cabinet/CabinetGrotesk-Black.woff2') format('woff2'),
             url('/assets/cabinet/CabinetGrotesk-Black.woff') format('woff'),
             url('/assets/cabinet/CabinetGrotesk-Black.ttf') format('truetype');
             font-weight: 900;
             font-display: swap;
             font-style: normal;
      }

      html {
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        font-family: system-ui, sans-serif;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        touch-action: manipulation;
      }

      body {
        position: relative;
        min-height: 100%;
        margin: 0;
        font-feature-settings: "kern";
      }

      ${scope} :where(*, *::before, *::after) {
        border-width: 0;
        border-style: solid;
        box-sizing: border-box;
        word-wrap: break-word;
      }

      main {
        display: block;
      }

      ${scope} hr {
        border-top-width: 1px;
        box-sizing: content-box;
        height: 0;
        overflow: visible;
      }

      ${scope} :where(pre, code, kbd,samp) {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, monospace;
        font-size: 1em;
      }

      ${scope} a {
        background-color: transparent;
        color: inherit;
        text-decoration: inherit;
      }

      ${scope} abbr[title] {
        border-bottom: none;
        text-decoration: underline;
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted;
      }

      ${scope} :where(b, strong) {
        font-weight: bold;
      }

      ${scope} small {
        font-size: 80%;
      }

      ${scope} :where(sub,sup) {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
      }

      ${scope} sub {
        bottom: -0.25em;
      }

      ${scope} sup {
        top: -0.5em;
      }

      ${scope} img {
        border-style: none;
      }

      ${scope} :where(button, input, optgroup, select, textarea) {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
      }

      ${scope} :where(button, input) {
        overflow: visible;
      }

      ${scope} :where(button, select) {
        text-transform: none;
      }

      ${scope} :where(
          button::-moz-focus-inner,
          [type="button"]::-moz-focus-inner,
          [type="reset"]::-moz-focus-inner,
          [type="submit"]::-moz-focus-inner
        ) {
        border-style: none;
        padding: 0;
      }

      ${scope} fieldset {
        padding: 0.35em 0.75em 0.625em;
      }

      ${scope} legend {
        box-sizing: border-box;
        color: inherit;
        display: table;
        max-width: 100%;
        padding: 0;
        white-space: normal;
      }

      ${scope} progress {
        vertical-align: baseline;
      }

      ${scope} textarea {
        overflow: auto;
      }

      ${scope} :where([type="checkbox"], [type="radio"]) {
        box-sizing: border-box;
        padding: 0;
      }

      ${scope} input[type="number"]::-webkit-inner-spin-button,
      ${scope} input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
      }

      ${scope} input[type="number"] {
        -moz-appearance: textfield;
      }

      ${scope} input[type="search"] {
        -webkit-appearance: textfield;
        outline-offset: -2px;
      }

      ${scope} input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none !important;
      }

      ${scope} ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
      }

      ${scope} details {
        display: block;
      }

      ${scope} summary {
        display: list-item;
      }

      template {
        display: none;
      }

      [hidden] {
        display: none !important;
      }

      ${scope} :where(
          blockquote,
          dl,
          dd,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          hr,
          figure,
          p,
          pre
        ) {
        margin: 0;
      }

      ${scope} button {
        background: transparent;
        padding: 0;
      }

      ${scope} fieldset {
        margin: 0;
        padding: 0;
      }

      ${scope} :where(ol, ul) {
        margin: 0;
        padding: 0;
      }

      ${scope} textarea {
        resize: vertical;
      }

      ${scope} :where(button, [role="button"]) {
        cursor: pointer;
      }

      ${scope} button::-moz-focus-inner {
        border: 0 !important;
      }

      ${scope} table {
        border-collapse: collapse;
      }

      ${scope} :where(h1, h2, h3, h4, h5, h6) {
        font-size: inherit;
        font-weight: inherit;
      }

      ${scope} :where(button, input, optgroup, select, textarea) {
        padding: 0;
        line-height: inherit;
        color: inherit;
      }

      [data-js-focus-visible]
        :focus:not([data-focus-visible-added]):not(
          [data-focus-visible-disabled]
        ) {
        outline: none;
        box-shadow: none;
      }

      ${scope} select::-ms-expand {
        display: none;
      }
    `} />
);

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
    white: '#FFF8ED',
    pink: '#FFA3FB',
};

const customScrollBar = {
    '::-webkit-scrollbar': {
        width: '8px',
    },
    '::-webkit-scrollbar-track': {
        width: '15px',
        bg: '#191825',
    },
    '::-webkit-scrollbar-thumb': {
        background: 'pink',
        borderRadius: '2px',
    },
};

export const theme = extendTheme({
    config: {
        initialColorMode: 'dark', // 'dark' | 'light'
        useSystemColorMode: false,
    },
    initialColorMode: 'dark', // 'dark' | 'light'
    useSystemColorMode: false,
    colors,
    fonts: {
        body: `'CabinetGrotesk-Variable', sans-serif`,
        heading: `'CabinetGrotesk-Variable', sans-serif`,
        // Add any other font styles you want to customize
    },
    styles: {
        global: {
            body: {
                ...customScrollBar,
            },
        },
    },
});
