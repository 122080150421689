export default {
    home: {
        heading: 'Vítejte v',
        headingName: 'Augmia',
        scanCode: 'Skenovat kód',
        orderText: 'Stále od nás nemáte produkt? Objednejte',
        orderLink: 'zde',
        addToHomeText: "Abyste si přidal naši aplikaci do telefonu, tak stiskněte ve vašem prohlížečí tlačítko sdílet a vyberte 'Přidat na plochu.'",
        addToHomeButton: 'Přidat na plochu',
        qrCodeScanningStates: {
            searching: 'HLEDÁM QR KÓD',
            searchingWithHint: 'PŘIBLIŽTE SE',
            found: 'QR KÓD NALEZEN',
        },
        exitScanner: 'Opustit Scanner',
    },
    selectEffect: {
        heading: 'Výběr animace',
        effects: {
            hearts: 'Srdce',
            butterflies: 'Motýli',
            sparks: 'Jiskry',
            empty: 'Bez efektu',
        },
    },
    startButton: {
        text: 'Start',
    },
    viewer: {
        exitApp: 'Odejít',
        canvasDetectionStates: {
            searching: 'Hledám Cíl',
            searchingWithHint: 'Namiřte kameru na Cíl ',
            found: 'Cíl NALEZEN',
            empty: 'PRÁZDNÝ',
        },
    },
} as const;
