import posthog from 'posthog-js'; // new
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import LandscapeOrientationPopup from '../../../components/LandscapeOrientationPopup';
import MuteButton from '../../../components/MuteButton';
import StartPage from "./@start/page";
import Arify from "./@viewer/page";


export default function ArifyPage({ arApps }: any) {
    let location = useLocation(); // new
    const [arStarted, setArStarted] = useState(false);


    React.useEffect(() => { // new
        posthog.capture('$pageview')
    }, [location]);


    return <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {/* Viewer Component */}
        <Arify arApps={arApps} arStarted={arStarted} />
        {/* StartPage Component overlaid on top */}
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: arStarted ? 'none' : 'block',
                zIndex: 1,
            }}
        >
            <StartPage arApps={arApps} onStart={() => setArStarted(true)} />
        </div>
        {arStarted && <MuteButton />}
        <LandscapeOrientationPopup />

    </div>
    //!showArViewer ? <StartPage arApps={arApps} /> : 
}
