import { create } from 'zustand';
import { CanvasDetectionStates } from '../model-types/AppModels';

type State = {
    scanningState: CanvasDetectionStates;
};

type Action = {
    setScanningState: (scanningState: CanvasDetectionStates) => void;

};

export const useCanvasScannerStore = create<State & Action>((set) => ({
    scanningState: CanvasDetectionStates.SEARCHING,
    setScanningState: (scanningState) => set(() => ({ scanningState })),

}));
