import { apiClient, getSubdomain } from "../libs/api-client";

export const BrandingWrapper = ({ children }) => {
    const subdomain = getSubdomain();
    console.log(subdomain, "SUBDOMAIN")
    const { data = [], isLoading } = apiClient.brandings.getBrandingPublic.useQuery(subdomain)

    console.log(data, isLoading, "DATA");

    return children

}