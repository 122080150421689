import { Box } from '@chakra-ui/react';
import LoadingComponent from './LoadingComponent';
import AppLayout from './AppLayout';
import { useAppStore } from '../stores/AppStore';


export default function LoadingWithStore() {
    const isLoading = useAppStore((state) => state.isLoading );

    return isLoading && <Box width={'100vw'} height={'100vh'} position="fixed" zIndex="10000">
        <AppLayout gridTemplateRows="50px 1fr 100px">
            <LoadingComponent />
        </AppLayout>
    </Box>
};
