export enum selectedEffectEnum {
    BUTTERFLY_EFFECT = 'BUTTERFLY_EFFECT',
    SPARKS_EFFECT = 'SPARKS_EFFECT',
    HEARTS_EFFECT = 'HEARTS_EFFECT',
    EMPTY_EFFECT = 'EMPTY_EFFECT'
}

export enum Routes {
    HOME = 'Home',
    LIVE_VIEW = 'LiveView',
    START = 'Start',
    SCANNING = 'Scanning',
}

export enum QrCodeScannerStates {
    SEARCHING = 'SEARCHING FOR QR CODE...',
    SEARCHING_WITH_HINT = 'MOVE CLOSER',
    FOUND = 'QR CODE FOUND'
}

export enum CanvasDetectionStates {
    SEARCHING = 'SEARCHING FOR CANVAS...',
    SEARCHING_WITH_HINT = 'COME CLOSER',
    FOUND = 'CANVAS FOUND',
    EMPTY = 'EMPTY',
}

export enum QrTypes {
    DARK = 'dark',
    LIGHT = 'light',
}
