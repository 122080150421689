
import { Box } from '@chakra-ui/react';
import { SceneType } from '@prisma/client';

import Logo from '../../../../components/Logo';
import ScanningAnimation from "../../../../components/ScanningAnimation";

import { lazy, Suspense } from 'react';


const ImageTrackingViewer = lazy(() => import('./image-tracking'));
const FaceTrackingViewer = lazy(() => import('./face-tracking'));
const GpsTrackingViewer = lazy(() => import('./gps-tracking/custom'));

const getViewerForScene = (sceneType: SceneType) => {
    if (sceneType === SceneType.IMAGE_TRACKING) {
        return ImageTrackingViewer;
    }
    if (sceneType === SceneType.FACE_TRACKING) {
        return FaceTrackingViewer;
    }
    if (sceneType === SceneType.GPS_TRACKING) {
        return GpsTrackingViewer;
    }
    return null;
};


export default function ArifyView({ arApps, arStarted = false }: any) {
    const scene = arApps[0]
    const Viewer = getViewerForScene(scene.type)

    return <>
        <ScanningAnimation />
        <Box position='fixed' top='0' left='0' right='0' display='flex' justifyContent='center' alignItems='center' height='50px'>
            <Logo />
        </Box>
        <Box style={{
            // width: window.innerWidth + "px",
            // height: window.innerHeight + "px",
            width: "100vw",
            height: "100vh",
            position: "relative",
            overflow: "hidden",
        }}>
            <Suspense fallback={<div>Loading...</div>}>
                {Viewer && <Viewer arApps={arApps} arStarted={arStarted} />}
            </Suspense>
            {/* <MediaCapture /> */}
            {/* <ArViewerControls /> */}

        </Box>
    </>

}
