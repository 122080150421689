import { CheckCircleIcon } from '@chakra-ui/icons';
import { Badge } from '@chakra-ui/react';
import { DotLoader } from 'react-spinners';
import { CanvasDetectionStates, QrCodeScannerStates } from '../model-types/AppModels';
import { useTranslation } from 'react-i18next';
// Test
export default function ScanningStatusBadge(props: any) {
    const { t } = useTranslation('app', { keyPrefix: "viewer.canvasDetectionStates" });
    const { t: tt } = useTranslation('app', { keyPrefix: "home.qrCodeScanningStates" });
    const { backgroundColor, position, color, type } = props;
    return <>
        <Badge
            bg={backgroundColor}
            position={position}
            bottom='110px'
            borderRadius='50px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap='5px'
            padding='5px 20px 5px 20px'
            fontSize='14px'
            color={color || '#000'}
        >
            {type === CanvasDetectionStates.FOUND && <><CheckCircleIcon /><strong>{t('found')}</strong></>}
            {type === QrCodeScannerStates.FOUND && <><CheckCircleIcon /><strong>{tt('found')}</strong></>}
            {type === CanvasDetectionStates.SEARCHING_WITH_HINT && <strong>{t('searchingWithHint')}</strong>}
            {type === QrCodeScannerStates.SEARCHING_WITH_HINT && <strong>{tt('searchingWithHint')}</strong>}
            {type === CanvasDetectionStates.SEARCHING && <><DotLoader color="#000000" size={15} /><strong>{t('searching')}</strong></>}
            {type === QrCodeScannerStates.SEARCHING && <><DotLoader color="#000000" size={15} /><strong>{tt('searching')}</strong></>}
        </Badge>
    </>;
}
