import { create } from 'zustand';
import { QrCodeScannerStates } from '../model-types/AppModels';

type State = {
    scanningState: QrCodeScannerStates;
};

type Action = {
    setScanningState: (scanningState: QrCodeScannerStates) => void;

};

export const useQrScannerStore = create<State & Action>((set) => ({
    scanningState: QrCodeScannerStates.SEARCHING,
    setScanningState: (scanningState) => set(() => ({ scanningState })),

}));
