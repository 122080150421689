import React, { useEffect } from 'react';
import jsQR from 'jsqr';
import Webcam from 'react-webcam';
import { useQrScannerStore } from '../../stores/QrScannerStore';
import { useAppStore } from '../../stores/AppStore';
import { QrCodeScannerStates } from '../../model-types/AppModels';
import { useNavigate } from 'react-router-dom';

const QRCodeScanner: React.FC = () => {
    const navigate = useNavigate();
    const videoRef = React.useRef<Webcam>(null);
    const setScanningState = useQrScannerStore((state) => state.setScanningState);
    const updateIsLoading = useAppStore((state) => state.updateIsLoading);

    useEffect(() => {
        updateIsLoading(true);
        let animationFrameId: number;

        const captureFrame = () => {
            let foundQr = false;
            if (videoRef.current !== null && videoRef.current.video !== null) {
                const { video } = videoRef.current;

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');

                if (context && video.videoWidth > 0 && video.videoHeight > 0) {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;

                    context.drawImage(video, 0, 0, canvas.width, canvas.height);

                    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                    const code = jsQR(imageData.data, imageData.width, imageData.height);
                    if (code && code.data.includes('app.relive')) {
                        console.log('QR Code:', code.data);
                        foundQr = true;
                        setScanningState(QrCodeScannerStates.FOUND);
                        const isStaging = code.data.includes("staging.app");
                        const url = `/${code.data.replace(isStaging ? "https://staging.app.relive-ar.com/" : 'https://app.relive-ar.com/', '')}`;
                        navigate(url);
                    }
                } else {
                    // toggleCamera();
                }
            }
            if (!foundQr) {
                animationFrameId = requestAnimationFrame(captureFrame);
            }
        };

        captureFrame();
        return () => {
            cancelAnimationFrame(animationFrameId);
            setScanningState(QrCodeScannerStates.SEARCHING);
            updateIsLoading(false);
        };
    }, []);

    return <Webcam
        audio={false}
        ref={videoRef}
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            objectFit: 'cover',
            // zIndex: 100,
        }}
        onUserMedia={() => updateIsLoading(false)}
        videoConstraints={
            {
                facingMode: { ideal: 'environment' },

            }
        }
    />;
};

export default QRCodeScanner;
