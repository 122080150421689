

import { Box, Center, Image } from '@chakra-ui/react';
import { CanvasDetectionStates } from '../model-types/AppModels';
import { useCanvasScannerStore } from '../stores/CanvasScannerStore';


export default function ScannerOverlay() {
    const scanningState = useCanvasScannerStore((state) => state.scanningState);
    const shouldNotRenderScanningAnimation = scanningState === CanvasDetectionStates.FOUND || scanningState === CanvasDetectionStates.EMPTY;
    console.log(scanningState, "STATE", shouldNotRenderScanningAnimation)

    return <Center id="ui-scanning" position="absolute" width={'100%'} height={'100%'}>
        {!shouldNotRenderScanningAnimation && <ScanningAnimation />}
    </Center>;
}

function ScanningAnimation() {
    // useEffect(() => {
    //     const scannerLine = scannerLineRef.current;
    //     const scannerGradient = scannerGradientRef.current;
    //     const container = containerRef.current;
    //     const containerHeight = container.offsetHeight;

    //     let position = 0;
    //     let direction = 0;
    //     let animationFrameId: number;
    //     function animateScanner() {
    //         if (direction === 0) {
    //             position += containerHeight / 500;
    //         } else {
    //             position -= containerHeight / 500;
    //         }

    //         if (position > containerHeight * 0.80) {
    //             direction = 1;
    //         }

    //         if (position < containerHeight * 0.2) {
    //             direction = 0;
    //         }

    //         scannerLine.style.bottom = `${position}px`;
    //         if (direction === 0) {
    //             scannerGradient.style.bottom = `${position - scannerGradient.offsetHeight}px`;
    //             scannerGradient.style.transformOrigin = 'top';
    //             scannerGradient.style.background = 'linear-gradient(to bottom, rgba(255,163,251,0.4), transparent)';
    //         } else {
    //             scannerGradient.style.transformOrigin = 'bottom';
    //             scannerGradient.style.bottom = `${position}px`;
    //             scannerGradient.style.background = 'linear-gradient(to top, rgba(255,163,251,0.4), transparent)';
    //         }
    //         scannerGradient.style.opacity = '1';
    //         scannerLine.style.opacity = '1';
    //         const midPoint = containerHeight / 2;
    //         const scale = (-1 / (0.2 * containerHeight - midPoint) ** 2) * (position - midPoint) ** 2 + 1;
    //         scannerGradient.style.transform = `scaleY(${scale})`;
    //         animationFrameId = requestAnimationFrame(animateScanner);
    //     }

    //     animateScanner();
    //     return () => {
    //         cancelAnimationFrame(animationFrameId);
    //     };
    // }, []);

    return (
        <Box id='scanner'
            position='fixed'
            width='50%'
            height='0'
            paddingBottom='40%'
            box-sizing='border-box'
            border='0px solid rgba(158, 140, 242, 1)'
            overflow='hidden'
        >
            <Box
                id='scanner-line'
                position='absolute'
                bottom='1'
                height='2%'
                width='100%'
                backgroundColor='rgba(158, 140, 242, 1)'
                opacity='0'
            >
            </Box>
            <Box id='scanner-gradient'
                position='absolute'
                bottom='0'
                height='40%'
                width='100%'
                background='linear-gradient(to bottom, rgba(255, 163, 251, 0.2), transparent)'
                opacity='0'
                transformOrigin='bottom'
            >
            </Box>
            <Image src='/scanner-purple.svg'
                position='absolute'
                top='50%'
                left='50%'
                transform='translate(-50%, -50%)'
                width='80%'
                height='100%'
                color="rgba(158, 140, 242, 1)"
            />
        </Box>
    );
}
