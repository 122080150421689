import { HStack, Menu, MenuButton, Image, MenuList } from '@chakra-ui/react';
import Logo from './Logo';
import LanguageSelector from './LanguageSelector';
import { Languages } from '../i18n/locales/settings';
import { useTranslation } from 'react-i18next';

export default function TopBanner() {
    const { i18n } = useTranslation();

    return <>
        <HStack
            padding='0 15px'
        >
            <Menu closeOnSelect={true}>
                <MenuButton>
                    <HStack gap='20px'>
                        <HStack gap='10px'>
                            <Image alt='cz-flag' width='20px' src={i18n.language === Languages.CS ? '/flag-cz.svg' : '/flag-en.svg'} />
                        </HStack>
                        {/* <TriangleDownIcon boxSize='11px' color='#FFA3FB' /> */}
                    </HStack>
                </MenuButton>
                <MenuList color='white' bg='#12111C' border='none'>
                    <LanguageSelector />
                </MenuList>
            </Menu>
        </HStack>
        <Logo />
    </>;
}
