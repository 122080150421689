import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingWithStore from "../../components/LoadingWithStore";
import { apiClient } from "../../libs/api-client";
import { useAppStore } from "../../stores/AppStore";
import ArifyPage from "./arify/page";

export default function ArPage() {
    const updateIsLoading = useAppStore(state => state.updateIsLoading)
    // const { updateTheme } = useThemeUpdater();
    const navigate = useNavigate()


    const { arAppId } = useParams();
    const [search] = useSearchParams()
    const { data = [], isLoading, error } = apiClient.scenes.getScenePublic.useQuery({ sceneId: arAppId, collectionId: search.get("isCollection") ? arAppId : undefined })


    React.useEffect(() => {
        if (!isLoading) {
            updateIsLoading(false)
            if (error?.data && error.data.code === "NOT_FOUND") {
                navigate("/404")
            }
            // updateTheme(data.branding.themeColors)
        }

    }, [isLoading])

    const arApps = structuredClone(Array.isArray(data) ? data : [data])

    return <>
        <LoadingWithStore />
        {arApps.length && <ArifyPage arApps={arApps} />}
    </>
}