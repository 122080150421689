import { Box, IconButton, useToast, useToken } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa'

export default function MuteButton() {
    const [isMuted, setIsMuted] = useState(false)
    const [purple600, blue600] = useToken('colors', ['purple.600', 'blue.600'])
    const toast = useToast()

    const toggleMute = useCallback(() => {
        const videos = document.getElementsByTagName('video')
        const newMuteState = !isMuted

        Array.from(videos).forEach(video => {
            video.muted = newMuteState

        })

        setIsMuted(newMuteState)

        // Show a toast notification
        // toast({
        //     title: newMuteState ? 'Audio Muted' : 'Audio Unmuted',
        //     status: 'info',
        //     duration: 2000,
        //     isClosable: true,
        //     position: 'top',
        // })
    }, [isMuted, toast])

    // Effect to sync mute state with video elements on component mount
    // useEffect(() => {
    //     // TODO: Fix the initial mute - fixed by removing this use state 
    //     const videos = document.getElementsByTagName('video')
    //     Array.from(videos).forEach(video => {
    //         video.muted = isMuted
    //     })
    // }, [isMuted])

    return (
        <Box
            position="fixed"
            bottom="6"
            left="50%"
            transform="translateX(-50%)"
            zIndex={50}
        >
            <IconButton
                aria-label={isMuted ? 'Unmute' : 'Mute'}
                icon={isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                onClick={toggleMute}
                rounded="full"
                w="14"
                h="14"
                bg="rgba(0, 0, 0, 0.5)"
                color="white"
                fontSize="24px"
                backdropFilter="blur(8px)"
                boxShadow="lg"
                _before={{
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    bg: `linear-gradient(135deg, ${purple600}, ${blue600})`,
                    opacity: 0.75,
                    borderRadius: 'full',
                    zIndex: -1,
                }}
                _hover={{
                    transform: 'scale(1.1)',
                    bg: 'rgba(0, 0, 0, 0.7)',
                }}
                _active={{
                    transform: 'scale(0.95)',
                    boxShadow: 'inner',
                }}
                transition="all 0.3s"
            />
        </Box>
    )
}