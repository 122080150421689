import { StateCreator } from 'zustand';

interface ChatwootSliceState {
    isChatShown: boolean,
    isChatLoaded: boolean,
    isMobileChatShown: boolean,
};

interface ChatwootSliceAction {
    setChatShow: () => void
    setChatHidden: () => void;
    setChatLoaded: (isChatLoaded: boolean) => void
    setMobileShown: (isMobileShown: boolean) => void
};

export type ChatwootSlice = ChatwootSliceState & ChatwootSliceAction;

export const createChatwootSlice: StateCreator<
    ChatwootSlice,
    [],
    [],
    ChatwootSlice
> = (set) => ({
    isChatLoaded: false,
    isMobileChatShown: false,
    isChatShown: false,
    setChatShow: () => {
        set({ isChatShown: true });
    },
    setMobileShown: (isMobileShown) => {
        set({ isMobileChatShown: isMobileShown });
    },
    setChatHidden: () => {
        set({ isChatShown: false });
    },
    setChatLoaded: (isChatLoaded) => {
        set({ isChatLoaded });
    },
});
