
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorMode
} from "@chakra-ui/react"
import { useEffect, useState } from "react"

export default function CompatibilityPopup() {
    const [isOpen, setIsOpen] = useState(false)
    const [deviceInfo, setDeviceInfo] = useState({ isIOS: false, isAndroid: false, browser: "" })
    const { colorMode } = useColorMode()

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase()
        const isIOS = /iphone|ipad|ipod/.test(userAgent)
        const isAndroid = /android/.test(userAgent)
        const isSafari = /safari/.test(userAgent) && !/chrome/.test(userAgent)
        const isChrome = /chrome/.test(userAgent)

        setDeviceInfo({
            isIOS,
            isAndroid,
            browser: isSafari ? "Safari" : isChrome ? "Chrome" : "Other"
        })

        const shouldShowPopup = (isIOS && !isSafari && !isChrome) || (isAndroid && !isChrome)
        setIsOpen(shouldShowPopup)
    }, [])

    const getCompatibilityMessage = () => {
        if (deviceInfo.isIOS) {
            return "Please use Safari or Chrome on your iOS device for the best AR experience."
        } else if (deviceInfo.isAndroid) {
            return "Please use Chrome on your Android device for the best AR experience."
        } else {
            return "For the best AR experience, please use a supported mobile device and browser."
        }
    }

    const bgColor = colorMode === "dark" ? "#1A202C" : "white"
    const textColor = colorMode === "dark" ? "white" : "#1A202C"
    const accentColor = "#9C2CF2"

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} isCentered>
            <ModalOverlay />
            <ModalContent bg={bgColor} color={textColor}>
                <ModalHeader>
                    Compatibility Notice
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>{getCompatibilityMessage()}</Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="purple" onClick={() => setIsOpen(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}